import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { EntryListAPIResponse } from '@/types/ApiResponse';

export type ListFilterOdometer = `${number}` | 'MAX';

export enum EntryListFilterType {
  UsedAndNew = 'kaikki',
  OnlyUsed = 'kaytetyt',
  OnlyNew = 'uudet',
}

export interface EntryListFilter {
  readonly soldForHighestBidder?: boolean;
  readonly onlyEnded?: boolean;
  readonly region?: string;
  readonly category?: number;
  readonly searchTerm?: string;
  readonly class?: string;
  readonly company?: number;
  readonly metadataManufacturer?: string;
  readonly metadataDrive?: number;
  readonly metadataOdometer?: ListFilterOdometer;
  readonly metadataTransmission?: number;
  readonly metadataFuelType?: number;
  readonly metadataYearModelStart?: number;
  readonly metadataYearModelEnd?: number;
  readonly type?: EntryListFilterType;
  readonly isFastBidPeriod?: boolean;
  readonly groupId?: number;
  readonly showPrivateAuctions?: boolean;
}

export const filterToQueryParam: Record<keyof EntryListFilter, string> = {
  soldForHighestBidder: 'met',
  onlyEnded: 'paattyneet',
  region: 'region',
  type: 'tyyppi',
  searchTerm: 'term',
  category: 'category',
  class: 'class',
  company: 'company',
  metadataManufacturer: 'merkki',
  metadataDrive: 'vetotapa',
  metadataOdometer: 'km',
  metadataTransmission: 'vaihteisto',
  metadataFuelType: 'polttoaine',
  metadataYearModelStart: 'vuosimalliMin',
  metadataYearModelEnd: 'vuosimalliMax',
  isFastBidPeriod: 'isFastBidPeriod',
  groupId: 'groupId',
  showPrivateAuctions: 'showPrivateAuctions',
};

export enum EntryListSort {
  Ending = 'paattyvat',
  MostViewed = 'katsotuimmat',
  Newest = 'uusimmat',
}

export interface EntryListQuery {
  readonly filter: EntryListFilter;
  readonly sort: EntryListSort;
  readonly page?: number;
  readonly limit?: number;
}

export const useEntryList = ({ filter, sort, page, limit }: EntryListQuery) => {
  const { apiFetch } = useApi();

  return useQuery<EntryListAPIResponse>({
    queryKey: ['entries', 'list-page', filter, sort, page, limit],
    queryFn: async () => {
      const params = new URLSearchParams();

      Object.entries(filter).forEach(([name, value]) => {
        if (value === undefined) {
          return;
        }

        if (typeof value === 'boolean') {
          params.set(filterToQueryParam[name as keyof EntryListFilter], value ? '1' : '0');
          return;
        }

        params.set(filterToQueryParam[name as keyof EntryListFilter], String(value));
      });

      params.set('jarjestys', sort);

      if (page !== undefined) {
        params.set('sivu', page.toString());
      }

      if (limit !== undefined) {
        params.set('limit', limit.toString());
      }

      const response = await apiFetch(`/api/net-auctions/list?${params.toString()}`);

      return (await response.json()) as EntryListAPIResponse;
    },

    staleTime: 1000 * 5, // Same as NetAuction::VISIBILITY_GRACE_TIME_SECONDS on huutokaupat.
    placeholderData: keepPreviousData,
  });
};
