import React, { useEffect } from 'react';
import { Checkbox, Flex, Grid, Select, Text, TextInput, ToggleContent } from '@mezzoforte/forge';
import { Controller, FormProvider, useForm, UseFormRegister } from 'react-hook-form';
import { GridProps } from '@chakra-ui/react';
import { EntryListFilterType } from '@/hooks/useEntryList';
import * as categories from '@/types/Category';
import { ListPageRegionSelect } from '@/components/ListPage/ListPageRegionSelect';
import { ListPageManufacturerSelect } from '@/components/ListPage/ListPageManufacturerSelect';

export interface EntryListFilterValues {
  readonly soldForHighestBidder: boolean;
  readonly onlyEnded: boolean;
  readonly region: string;
  readonly type: EntryListFilterType;
  readonly metadataManufacturer?: string;
  readonly metadataDrive?: string;
  readonly metadataOdometer?: string;
  readonly metadataTransmission?: string;
  readonly metadataFuelType?: string;
  readonly metadataYearModelStart?: string;
  readonly metadataYearModelEnd?: string;
}

interface Filter {
  readonly key: string;
  readonly render: () => React.ReactElement;
}

function getFilters(register: UseFormRegister<EntryListFilterValues>, categoryId?: number): Filter[] {
  const filters: Filter[] = [
    { key: 'region', render: () => <ListPageRegionSelect label="Sijainti" {...register('region')} /> },
  ];

  // TODO: Extract this if we ever add more category-specific filters.
  if (
    categoryId !== undefined &&
    [categories.CATEGORY_PASSENGER_CAR, categories.CATEGORY_VAN, categories.CATEGORY_TRUCK].includes(categoryId)
  ) {
    filters.push({
      key: 'metadataManufacturer',
      render: () => <ListPageManufacturerSelect label="Merkki" {...register('metadataManufacturer')} />,
    });

    filters.push({
      key: 'metadataFuelType',
      render: () => (
        <Select label="Käyttövoima" {...register('metadataFuelType')}>
          <option value="">Kaikki</option>
          <option value="1">Bensiini</option>
          <option value="2">Diesel</option>
          <option value="3">Hybridi</option>
          <option value="4">Kaasu</option>
          <option value="5">Sähkö</option>
          <option value="6">E85-bensiini</option>
        </Select>
      ),
    });

    filters.push({
      key: 'metadataTransmission',
      render: () => (
        <Select label="Vaihteisto" {...register('metadataTransmission')}>
          <option value="">Kaikki</option>
          <option value="1">Manuaali</option>
          <option value="2">Automaatti</option>
        </Select>
      ),
    });

    filters.push({
      key: 'metadataOdometer',
      render: () => (
        <Select label="Mittarilukema" {...register('metadataOdometer')}>
          <option value="">Kaikki</option>
          <option value="50000">Alle 50 000 km</option>
          <option value="100000">Alle 100 000 km</option>
          <option value="150000">Alle 150 000 km</option>
          <option value="200000">Alle 200 000 km</option>
          <option value="250000">Alle 250 000 km</option>
          <option value="300000">Alle 300 000 km</option>
          <option value="350000">Alle 350 000 km</option>
          <option value="400000">Alle 400 000 km</option>
          <option value="MAX">Yli 400 000 km</option>
        </Select>
      ),
    });

    filters.push({
      key: 'metadataDrive',
      render: () => (
        <Select label="Vetotapa" {...register('metadataDrive')}>
          <option value="">Kaikki</option>
          <option value="1">Etuveto</option>
          <option value="2">Takaveto</option>
          <option value="3">Neliveto</option>
        </Select>
      ),
    });

    filters.push({
      key: 'metadataYearModel',
      render: () => (
        <Flex align="end" gap={2}>
          <TextInput label="Vuosimalli" placeholder="Minimi" type="number" {...register('metadataYearModelStart')} />
          <Text mb={2}>-</Text>
          <TextInput label="" placeholder="Maksimi" type="number" {...register('metadataYearModelEnd')} />
        </Flex>
      ),
    });
  }

  filters.push({
    key: 'type',
    render: () => (
      <Select label="Kunto" {...register('type')}>
        <option value={EntryListFilterType.UsedAndNew}>Kaikki</option>
        <option value={EntryListFilterType.OnlyUsed}>Käytetyt</option>
        <option value={EntryListFilterType.OnlyNew}>Uudet</option>
      </Select>
    ),
  });

  filters.push({
    key: 'checkboxes',
    render: () => (
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'start', md: 'center' }}
        gap={{ base: 0, md: 4 }}
        gridColumn={{ base: 1, md: 'span 2' }}
      >
        <Controller<EntryListFilterValues>
          name="soldForHighestBidder"
          render={({ field }) => (
            <Checkbox isChecked={Boolean(field.value)} onChange={field.onChange}>
              Myydään eniten tarjoavalle
            </Checkbox>
          )}
        />
        <Controller<EntryListFilterValues>
          name="onlyEnded"
          render={({ field }) => (
            <Checkbox isChecked={Boolean(field.value)} onChange={field.onChange}>
              Vain päättyneet
            </Checkbox>
          )}
        />
      </Flex>
    ),
  });

  return filters;
}

function FilterGrid(props: GridProps) {
  return (
    <Grid
      templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr 1fr' }}
      gridColumnGap={4}
      gridRowGap={3}
      alignItems="end"
      {...props}
    />
  );
}

interface FiltersProps {
  readonly filters: Filter[];
  readonly alwaysVisibleCount: number;
}

function Filters({ filters, alwaysVisibleCount }: FiltersProps) {
  const alwaysVisibleFilters = filters.slice(0, alwaysVisibleCount);
  const collapsibleFilters = filters.slice(alwaysVisibleCount);

  return (
    <>
      <FilterGrid>
        {alwaysVisibleFilters.map((filter) => (
          <React.Fragment key={filter.key}>{filter.render()}</React.Fragment>
        ))}
      </FilterGrid>

      {collapsibleFilters.length > 0 && (
        <ToggleContent textShow="Rajaa tarkemmin" textHide="Piilota rajaukset" buttonProps={{ mt: 3 }}>
          <FilterGrid mt={3}>
            {collapsibleFilters.map((filter) => (
              <React.Fragment key={filter.key}>{filter.render()}</React.Fragment>
            ))}
          </FilterGrid>
        </ToggleContent>
      )}
    </>
  );
}

interface EntryListFilterProps {
  categoryId?: number;
  defaultValues?: Partial<EntryListFilterValues>;
  onSubmit: (values: EntryListFilterValues) => void;
}

export function EntryListFilter({ categoryId, defaultValues, onSubmit }: EntryListFilterProps) {
  const form = useForm<EntryListFilterValues>({ defaultValues });

  const { register, handleSubmit, watch } = form;

  useEffect(() => {
    // Submit on change.
    const subscription = watch(() => {
      void handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onSubmit]);

  const filters = getFilters(register, categoryId);

  return (
    <FormProvider {...form}>
      <form>
        <Filters filters={filters} alwaysVisibleCount={4} />
      </form>
    </FormProvider>
  );
}
