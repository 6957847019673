'use client';

import { useState } from 'react';
import { useClientRendering } from '@/hooks/useClientRendering';
import useSendAnalytics from '@/hooks/useSendAnalytics';
import { EntryListQuery } from '@/hooks/useEntryList';
import { EntryListAPIResponse } from '@/types/ApiResponse';
import { ListAnalytics } from '@/types/ListEntry';
import { CustomEventParams, gtmService } from '@/util/gtm-service';
import { entryListItemToListEntry } from '@/util/entry';
import { listEntryToEcommerceItem } from '@/util/ecommerce';
import { useCategories } from '@/hooks/useCategories';

interface EntryListAnalyticsProps {
  analytics: ListAnalytics;
  query: EntryListQuery;
  data?: EntryListAPIResponse;
  custom?: CustomEventParams;
}

export function EntryListAnalytics({ analytics, query, data, custom }: EntryListAnalyticsProps) {
  const [isSent, setIsSent] = useState(false);
  const sendAnalytics = useSendAnalytics();
  const isClient = useClientRendering();
  const { data: categoriesData } = useCategories();

  if (!isSent && isClient && data !== undefined && categoriesData !== undefined) {
    gtmService.recommendedEcommerce.viewItemList(
      analytics.listId,
      analytics.listName,
      data.entries
        .map(entryListItemToListEntry)
        .map((entry, index) => listEntryToEcommerceItem(entry, categoriesData.categories, index)),
      custom
    );
    sendAnalytics('entryListPage', {
      category: query.filter.category,
      searchTerm: query.filter.searchTerm,
      sorting: query.sort,
      area: query.filter.region,
      met: query.filter.soldForHighestBidder ?? false,
      itemType: query.filter.type,
      pageNumber: query.page,
      pageCount: data.pages.pageCount,
      resultsCount: data.pages.totalItemCount,
    });
    setIsSent(true);
  }

  return null;
}
