import React, { forwardRef } from 'react';
import { Select, SelectProps } from '@mezzoforte/forge';
import { useRegions } from '@/hooks/useRegions';

export const ListPageRegionSelect = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { status, data } = useRegions();

  const regions = status === 'success' ? data : [];

  const options = [
    { label: 'Koko Suomi', value: '' },
    ...regions.map((region) => ({ label: region.name, value: region.code })),
  ];

  return (
    <Select ref={ref} {...props}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
});
